import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [

    {
        path: '/quotes',
        component: asyncComponent(() => import('../Quotes/Quotes')),
        exact: false
    },
    {
        path: '/static-pages',
        component: asyncComponent(() => import('../StaticPages/StaticPages')),
        exact: false
    },
    {
        path: '/templates',
        component: asyncComponent(() => import('../Templates/Templates')),
        exact: false
    },
    {
        path: '/contacts',
        component: asyncComponent(() => import('../Contacts/Contacts')),
        exact: false
    },
    {
        path: '/reports',
        component: asyncComponent(() => import('../AbuseReports/list')),
        exact: false
    },
    {
        path: '/gamification',
        component: asyncComponent(() => import('../Gamification/Gamification')),
        exact: false
    },
    {
        path: '/users',
        component: asyncComponent(() => import('../Users/Users')),
        exact: false
    },
    {
        path: '/moods',
        component: asyncComponent(() => import('../Moods/Moods')),
        exact: false
    },

    {
        path: '/posts/comments/:post',
        component: asyncComponent(() => import('../Comments/Comments')),
        exact: false
    },
    {
        path: '/posts/:post_type',
        component: asyncComponent(() => import('../Posts/Posts')),
        exact: false
    },
    {
        path: '/settings',
        component: asyncComponent(() => import('../Settings/Settings')),
        exact: false
    },
    {
        path: '/update-profile',
        component: asyncComponent(() => import('../ChangePassword/ChangePassword')),
        exact: false
    },

    {
        path: '/dashboard',
        component: asyncComponent(() => import('../Widgets/index.js')),
        exact: false
    },
    {
        path: '/*',
        component: asyncComponent(() => import('../Page/404')),
        exact: false
    },
];

class AppRouter extends Component {
    render() {
        const {url, style} = this.props;
        return (
            <div style={style}>
                <Switch>
                    {routes.map(singleRoute => {
                        const { path, exact, ...otherProps } = singleRoute;
                        return (
                            <Route
                                exact={singleRoute.exact}
                                key={singleRoute.path}
                                path={singleRoute.path}
                                {...otherProps}
                            />
                        );
                    })}
                </Switch>


            </div>
        );
    }
}

export default AppRouter;
