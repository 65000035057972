import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'react-router-redux';
import {connect} from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({component: Component, isLoggedIn, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />

);
const PublicRoutes = ({history, isLoggedIn}) => {

    console.log(isLoggedIn, typeof isLoggedIn, isLoggedIn ? "IF" : "else");
    return (
        <ConnectedRouter history={history}>

            <Switch>
                {/*isLoggedIn ?*/}


                {/*</>*/}
                {/*:*/}
                {/*<>*/}
                    {/*{console.log("AAAA")}*/}
                <Route
                    exact
                    path={'/'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />
                <Route
                    exact
                    path={'/signin'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />
                <Route
                    exact
                    path={'/forgot-password'}
                    component={asyncComponent(() => import('./containers/Page/forgot'))}
                />
                <Route
                    exact
                    path={'/verify-otp/:email'}
                    component={asyncComponent(() => import('./containers/Page/verify'))}
                />
                <Route
                    exact
                    path={'/reset-password/:email'}
                    component={asyncComponent(() => import('./containers/Page/reset'))}
                />
                <Route
                    exact
                    path={'/privacy-policy'}
                    component={asyncComponent(() => import('./containers/Page/privacy'))}
                />
                    <RestrictedRoute
                        path="/"
                        component={App}
                        isLoggedIn={isLoggedIn}
                    />
                {/*</>*/}

            </Switch>
        </ConnectedRouter>
    );
};

export default connect(state => ({
    isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
