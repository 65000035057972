export default {
    apiUrl: 'http://yoursite.com/api/',
};

const siteConfig = {
    siteName: 'HelpACE',
    siteIcon: '',
    footerText: 'HelpACE ©2020 ',
};
const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
    fetchUrl: '/api/',
    secretKey: 'secretKey',
};

export {siteConfig, language, themeConfig, jwtConfig};
