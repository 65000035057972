import React from 'react';
import {Link} from 'react-router-dom';
import {siteConfig} from '../../settings';
import logo from '../../image/logo.png';
import frontlogo from '../../image/frontlogo.png';

export default ({collapsed, signin}) => {
    return (
        <div className="isoLogoWrapper">

            {collapsed && !signin ? (
                <div>
                    <h3>
                        <Link to="/dashboard">
                            <img src={logo} alt="" width={"50%"}/>
                        </Link>
                    </h3>
                </div>
            ) : !signin ? (

                    <h3>
                        <Link to="/">

                            <img src={logo} alt="" width={"30%"}/> {siteConfig.siteName}
                        </Link>
                    </h3>
                ) :
                <h3>
                    <Link to="/">
                        <img src={frontlogo} alt="" width={"50%"}/>
                    </Link>
                </h3>
            }
        </div>
    );
};
