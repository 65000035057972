const options = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        leftIcon: 'ion-podium',
    },
    {
        key: 'quotes',
        label: 'Quotes',
        leftIcon: 'ion-quote',
    },
    {
        key: "moods",
        label: "Mood",
        leftIcon: "ion-happy-outline"
    },
    {
        key: "posts/1",
        label: "Posts",
        leftIcon: "ion-compose"
    },
    {
        key: 'users',
        label: 'Users',
        leftIcon: 'ion-ios-people',
    },
    {
        key: 'contacts',
        label: 'Contact',
        leftIcon: 'ion-ios-email',
    },
    {
        key: 'reports',
        label: 'Abuse Reports',
        leftIcon: 'ion-alert-circled',
    },
    {
        key: "static-pages",
        label: "Static Content",
        leftIcon: "ion-document-text"
    },
    {
        key: "templates",
        label: "Templates",
        leftIcon: "ion-paintbucket"
    },
    {
        key: "gamification",
        label: "Gamification",
        leftIcon: "ion-trophy"
    },

];
export default options;
