import moment from 'moment-timezone'
import React from 'react';
//import Pagination from "react-js-pagination";
import _ from "lodash";
import {Link} from "react-router-dom";
import {Bounce, toast} from "react-toastify";

/*
import Pagination from 'react-bootstrap/Pagination'
*/

// capitalize first letter of the string
export function ucfirst(str) {
  return (str) ? str.slice(0, 1).toUpperCase() + str.slice(1, str.length) : '';
}

// change date format
export function chnageDateFormate(date) {

  //return (moment.tz(date,'Asia/Kolkata')).format('LLL');
  return moment(date).format('LLL');

  /*if(typeof date == 'string')
  {
    let t = date.split('T');
    let d = moment(t[0]).format('DD MMM YYYY');
    console.log(t[1]);
    let v = moment(t[1]).format('hh:mm:ss a');
    return d + ' '+ v;
  }*/

}

export function changeDate(date) {

  //return (moment.tz(date,'Asia/Kolkata')).format('LLL');
  return moment(date).format('DD MMM YYYY');

  /*if(typeof date == 'string')
  {
    let t = date.split('T');
    let d = moment(t[0]).format('DD MMM YYYY');
    console.log(t[1]);
    let v = moment(t[1]).format('hh:mm:ss a');
    return d + ' '+ v;
  }*/

}

// get pagination links
export function pagination(currentPage, totalPages, count, limit, ref) {
    /* return (<Pagination
         activePage={currentPage}
         itemsCountPerPage={limit}
         totalItemsCount={count}
         pageRangeDisplayed={5}
         prevPageText={'<'}
         nextPageText={'>'}
         onChange={(e)=>ref.handlePageChange(e)}
     />)*/
}

// get array of matching values from array object
export function getArrayofMatchingValues(arrayobj, key, value) {
  let objs = _.takeWhile(arrayobj, function (o) {
    return o[key] === value;
  });
  return objs;
}

// find object from matching value
export function findByValue(arrayobj, key, value) {
  let objs = _.find(arrayobj, function (o) {
    return o[key] === value;
  });
  return objs;
}

// get difference between two array
export function getArrayDiffernce(array1, array2) {
  return _.differenceWith(array1, array2, _.isEqual)
}

// remove element from array
export function pop(array, value) {
  _.remove(array, function (n) {
    return n === value;
  });
  return array;
}

// convert data to form data recursively
export function getFormData(formData, data, previousKey) {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value instanceof Object && !Array.isArray(value)) {
      return getFormData(formData, value, key);
    }
    if (previousKey) {
      key = `${previousKey}[${key}]`;
    }
    if (Array.isArray(value)) {
      value.forEach((val, index) => {
        if (val instanceof Object && !Array.isArray(val)) {
          return getFormData(formData, val, `${key}[${index}]`);
        } else
          formData.append(`${key}[]`, val);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
}

// generate buttons
export function createButton(permission = {}, type, text, url, onclick = null, ref = null) {
  let btn = '';
  let user = JSON.parse(decrypt(localStorage.getItem('user')));
  if (user.is_sub_admin === true) {
    switch (type) {
      case 'ADD':
        btn = (permission.p_add) ? <Link title="Add" to={url} className="btn btn-sm btn-success"><i
          className="fa fa-plus text-white"> {text}</i> </Link> : '';
        break;
      case 'EDIT':
        btn = (permission.p_update) ? <Link title="Edit" to={url} className="btn btn-sm btn-info">{text} </Link> : '';
        break;
      case 'DELETE':
        btn = (permission.p_delete) ? <Link title="Delete" to='#' onClick={() => ref.deleteRecord(onclick)}
                                            className="btn btn-sm btn-danger">{text}</Link> : '';
        break;
      case 'VIEW':
        btn = (permission.p_view) ? <Link title="View" to={url} className="btn btn-sm btn-warning">{text} </Link> : '';
        break;
      case 'UPDATE':
        break;
      case 'BUTTON':
        btn = <button onClick={() => ref.props.history.push(onclick)}
                      className={'btn btn-info text-white pull-right'}> {text}
        </button>;
        break;
      case 'VIEWBTN':
        btn = (permission.p_view) ? <a title="View" href='#' onClick={() => ref.goToDetails(onclick.id)}
                                       className="btn btn-sm btn-warning">{text}</a> : '';
        break;
      case 'VIEW_PROVIDER':
        btn = (permission.p_view) ? <Link title="Providers" to={url} className="btn btn-sm btn-info">{text}</Link> : '';
        break;
    }
  } else {
    switch (type) {
      case 'ADD':
        btn = <Link title="Add" to={url} className="btn btn-sm btn-success"><i
          className="fa fa-plus text-white"> {text}</i> </Link>;
        break;
      case 'EDIT':
        btn = <Link title="Edit" to={url} className="btn btn-sm btn-info">{text} </Link>;
        break;
      case 'DELETE':
        btn = <Link title="Delete" to='#' onClick={() => ref.deleteRecord(onclick)}
                    className="btn btn-sm btn-danger">{text}</Link>;
        break;
      case 'VIEW':
        btn = <Link title="View" to={url} className="btn btn-sm btn-warning">{text} </Link>;
        break;
      case 'UPDATE':
        break;
      case 'BUTTON':
        btn = <button onClick={() => ref.props.history.push(onclick)}
                      className={'btn btn-info text-white pull-right'}> {text}
        </button>;
        break;
      case 'VIEWBTN':
        btn = <a title="View" href='#' onClick={() => ref.goToDetails(onclick.id)}
                 className="btn btn-sm btn-warning">{text}</a>;
        break;
      case 'VIEW_PROVIDER':
        btn = <Link title="Providers" to={url} className="btn btn-sm btn-info">{text}</Link>;
        break;
    }
  }
  return btn;
}

export default function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

}

export function encrypt(str) {
  let re = '';
  try {
    re = btoa(str);
  } catch (e) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.reload();

  }
  return re;

}

export function decrypt(str) {
  let re = null;
  try {
    re = atob(str);

    if (!IsJsonString(re) && re.length > 4) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.reload();
    }
    if (str === null) {
      re = null;
    }
    return re;
  } catch (e) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.reload();
  }
  return re;
}

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function alert(message,type)
{
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: 'top-center',
    type: type
  });
}
