import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import createHistory from 'history/createBrowserHistory';
import {routerMiddleware, routerReducer} from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';

const history = createHistory({basename: process.env.REACT_APP_BASE_PATH});
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer
    }),
    compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
export {store, history};
